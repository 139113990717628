import React from "react";
import s from "./Main.module.scss";
import Header from "../../UI/Header/Header";
import Footer from "../../UI/Footer/Footer";
import Content from "../Content/Content";

export default function Main() {
  return (
    <div className={s.container}>
      <Header />
      <Content />
      <Footer />
    </div>
  );
}
