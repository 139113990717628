import React from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";
import { ReactComponent as VkIcon } from "../../assets/icons/vk.svg";
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegram.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons//instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import s from "./Pleer.module.scss";
import cx from "classnames";

export default function Pleer({ className }) {
  return (
    <div className={cx(className)}>
      <AudioPlayer
        autoPlay
        className={s.pleer}
        src="https://listen5.myradio24.com/nomercy"
        showJumpControls={false}
        showDownloadProgress={false}
        showFilledProgress={false}
        customProgressBarSection={[]}
        customControlsSection={[
          RHAP_UI.MAIN_CONTROLS,
          <span
            className={s.currentSong}
            data-myinfo="song"
            style={{ color: "#fff" }}
          />,
          RHAP_UI.VOLUME_CONTROLS,
        ]}
      />

      <div className={cx(s.box, s.lastPlayed)}>
        <span className={s.lastPlayedTitle}>В ЭФИРЕ ДО ЭТОГО:</span>
        <div className="my_lastsongs" data-revert={1}>
            <div className={"my_lastsonghtml"}>
              <div className={s.lastSongItem}>
                <div className={s.lastSongItemTime}>%songtime%</div>
                <div className={s.lastSongItemSong}>%song%</div>
              </div>
            </div>
        </div>
      </div>

      <div className={cx(s.box, s.social)}>
        <span className={s.socialTitle}>МЫ В СОЦСЕТЯХ</span>
        <div className={s.socialLinks}>
          <a
            href="https://www.youtube.com/user/nomercyradiovideo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon className={s.icon} />
          </a>
          <a
            href="https://vk.com/nomercyradio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <VkIcon className={s.icon} />
          </a>
          <a
            href="https://t.me/nomercyradio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TelegramIcon className={s.icon} />
          </a>
          <a
            href="https://www.instagram.com/nomercyradio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className={s.icon} />
          </a>
          <a
            href="https://www.facebook.com/NOMERCYRADIO"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className={s.icon} />
          </a>
        </div>
      </div>
    </div>
  );
}
