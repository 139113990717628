import React from "react";
import s from "./Header.module.scss";
import logo from "../../assets/nomercy_radio_logo_new.png";

export default function Header() {
  return (
    <header className={s.header}>
      <img src={logo} className={s.logo} alt="nomercy radio logo" />
      <div className={s.textWrapper}>
        <span>
          NOMERCY RADIO - это современная интернет-рок радиостанция, начавшая
          свою деятельность 1 марта 2012 года! Ставка делается на лучшую музыку,
          самые интересные и живые программы, а так же эксклюзивные интервью!
        </span>
      </div>
      <div className={s.textWrapper}>
        <span> У НАС ВЫ МОЖЕТЕ НАЙТИ ИНТЕРВЬЮ С ТАКИМИ ГРУППАМИ КАК: </span>
        <br />
        <span>
          STIGMATA | NOIZE MC | JANE AIR | CROSSFAITH (Япония) | INFECTED RAIN |
          TRACKTOR BOWLING | ПСИХЕЯ | AMATORY | НУКИ | 5 DIEZ | ТАРАКАНЫ | НАИВ
          | LUMEN | ЭЛИЗИУМ | DISTEMPER | PURGEN | FAIL EMOTIONS | WILDWAYS |
          GRIZZLY KNOWS NO REMORSE | ПОРНОФИЛЬМЫ | СМЕХ | ПОРТ(812) | THE KOREA
          | LOUNA | ПЛАН ЛОМОНОСОВА | ПЛЯЖ | 7000$ | EVO | ЧЕТЫРЕ АПРЕЛЯ |
          RASHAMBA | ЛИНИЯ | PLUSH FISH | 2ND SEASON | ..В СЕБЕ и др
        </span>
        <br />
        <span>
          Мы стараемся поддерживать Российскую альтернативную сцену и давать
          шансы молодым группам!
        </span>
      </div>
    </header>
  );
}
