import React from "react";
import Pleer from "../../components/Pleer/Pleer";
import s from "./Content.module.scss";
import cx from "classnames";

export default function Content() {
  return (
    <div className={s.content}>
      <div className={cx(s.item, s.youtubeIframes)}>
        <iframe
          title="news"
          src="https://www.youtube.com/embed/videoseries?list=PLqXqRQpMkvDUYh3mXnMI7NS-MnHjgRE6d"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={s.ybIframe}
        ></iframe>
        <iframe
          title="8 random group"
          src="https://www.youtube.com/embed/videoseries?list=PLqXqRQpMkvDWhtNbQjo0aMeyOaMnTYOKW"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <iframe
          title="interview"
          src="https://www.youtube.com/embed/videoseries?list=PLqXqRQpMkvDUHGrVKdFrL_FwgTxHhnTBd"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={s.ybIframe}
        ></iframe>
      </div>
      <Pleer className={cx(s.item, s.pleer)} />
      <div className={cx(s.item, s.vk)}>
        <div id="vk_groups"></div>
      </div>
    </div>
  );
}
